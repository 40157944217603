import React, { useState, useEffect } from 'react';
import Head from 'next/head'
import Link from 'next/link'


import {
  Breadcrumb,
  Button,
  Container,
  // Image,
  Loader,
  Menu,
  Sidebar,
  Icon,
  Message,
} from 'semantic-ui-react'

import GameList from './GameList'
import AccountMenu from './acp/AccountMenu'
import NotificationMenu from './acp/NotificationMenu'
import ManagementMenu from './acp/ManagementMenu'
// import ClientSideRedirect from './ClientSideRedirect'
import { useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import config from '@/config';

type BreadcrumbElement = {
  key: string,
  content: any,
  active?: boolean,
  link?: boolean,
}

type Props = {
  title?: string,
  forUsers?: boolean,
  forGuests?: boolean,
  breadcrumbs?: Array<BreadcrumbElement>
}

import Router from 'next/router';

import { Media, mediaStyles, useWidth } from '@/lib/media';
import { redirLogin, useNetworkIssues, useUserOptions, setUserOptions } from '@/lib/utils';

const navigateTo = (url: string) => {
    return (e: any) => {
        e.preventDefault();
        Router.push(url)
        return false;
    }
};

import { IS_LOGGED_IN_QUERY } from '../graphql/apollo.query';


const Layout: React.FunctionComponent<Props> = ({
  children,
  title = 'This is the default title',
  forGuests = false,
  forUsers = true,
  breadcrumbs = [],
}) => {

  useWidth();

  // Handle sidebar state and visibility
  const [ sidebarOpen, setSidebarOpen ] = useState(false);

  const networkIssues = useNetworkIssues();
  const userOptions = useUserOptions();

  // USer login state
  const { loading: isCheckingSession, data } = useQuery(IS_LOGGED_IN_QUERY);

  let userCanView = true;
  useEffect(() => {
    if (forUsers && !forGuests && !isCheckingSession && !data?.isLoggedIn) {
      // If this is a navigation from an external source and we can't see any
      // cookies, try a refresh first in case we're being affected by samesite
      // errorneously tripping from the referer
      if (window.performance.navigation.type == 0) {
        window.location.reload()
      } else {
        window.localStorage.loginRedirect = Router.asPath;
        Router.push("/auth/login");
        userCanView = false;
      }
    } else
      if (forGuests && !forUsers && !isCheckingSession && !!data?.isLoggedIn) {
        Router.push("/dashboard");
        userCanView = false;
      }
  });

  // If logged in query is not yet executed, return null
  if (!data) return <Loader />;

  // Verify active session
  /*if (data.isLoggedIn && error) {
    navigateTo('/auth/logout');
    console.log("shite");
    return <>
      <Loader />
      <ClientSideRedirect href={'/auth/logout'} />
    </>;
  }*/

  const networkBreadcrumb = {
    key: "Network",
    active: breadcrumbs.length == 0,
    content: <Link href="/dashboard"><a>
      {config.site.name}
      {config.site.branch && config.site.branch != 'master' && <sup>{config.site.branch}</sup>}
    </a></Link>
  }

  let defaultCrumbs: Array<BreadcrumbElement> = [networkBreadcrumb];
  if (!!breadcrumbs && breadcrumbs.length > 0) {
    defaultCrumbs = [];
  }


  const mobileMenuButton = <Menu.Item as='a' onClick={() => {setSidebarOpen(true); document.getElementById("__next")?.children[0].scrollTo(0, 0);}}>
    <Icon name="bars" /> Menu
  </Menu.Item>


  const toggleDamage = () => {
    setUserOptions({ isDamaged: !userOptions.isDamaged });
  }

  const [aprilFools, setAprilFools] = React.useState(-1);

  useEffect(() => {
    const date = new Date();
    if (aprilFools == -1) {
      if (date.getDate() == 1 && date.getMonth() == 3)
        setAprilFools(1 + Math.round(Math.random() * 3))
      else
        setAprilFools(0);
    }
  })
  const foolsClass = aprilFools > 0 ? `aprilfools aprilfools${aprilFools}` : "";
  useEffect(() => {
    document.body.className = foolsClass;
  })

  return (
      <div className={foolsClass}>
      <Head>
        <title>{title ? title + " · " : ""}{config.site.name}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0, width=device-width, user-scalable=no" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="apple-mobile-web-app-title" content={config.site.name + ((config.site.branch && config.site.branch != 'master') ? ` (${config.site.branch})` : "")} />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="theme-color" content={`var(--color-${config.site.navbarColor})`} />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/imgs/symmetry-ios.png" />
        <link rel="icon" type="image/png" href="/imgs/symmetry-ios.png" />
        <script src="/js/viewport.js"></script>
        <style>{mediaStyles}</style>
      </Head>

        <Sidebar
          as={Menu}
          animation='overlay'
          color={config.site.navbarColor as any}
          onHide={() => setSidebarOpen(false)}
          vertical
          inverted
          direction={userOptions.isDamaged ? "left" : "right"}
          visible={sidebarOpen}
        >
          {data.isLoggedIn && <>
            <AccountMenu sidebar />
            <GameList sidebar />
            <ManagementMenu sidebar />
          </>}
        <Menu.Item>
            <Button fluid inverted basic action icon labelPosition={userOptions.isDamaged ? "right" : "left"} onClick={toggleDamage}>
                <Icon><FontAwesomeIcon icon={["far", "hand-paper"]} flip={userOptions.isDamaged ? "horizontal" : undefined}/></Icon>
            {userOptions.isDamaged ? "Right" : "Left"} hand mode
          </Button>
        </Menu.Item>
        </Sidebar>

          <Menu inverted fixed="top" color={config.site.navbarColor as any}>
            <Container>
              {!!data.isLoggedIn && userOptions?.isDamaged && mobileMenuButton}
              <Menu.Item as='a' header onClick={data?.isLoggedIn ? navigateTo('/dashboard') : navigateTo('/')}>
                {/*<Image size='mini' src='/logo.png' style={{ marginRight: '1.5em' }} />*/}
                {config.site.icon && <Icon children={<FontAwesomeIcon icon={["fad", config.site.icon]} />} />}
                {config.site.name}
                {config.site.branch && config.site.branch != 'master' && <sup>{config.site.branch}</sup>}
              </Menu.Item>

              {/* Logged in game list on non-mobile */}
              {!!data.isLoggedIn && <Media className="menu left" greaterThanOrEqual="tablet">
                <GameList />
              </Media>}

              {!!data.isLoggedIn && <>
                {/* Logged in user on non-mobile */}
                <Media className="menu right" greaterThanOrEqual="tablet">
                  {/* Logged in users */}
                  <NotificationMenu />
                  <ManagementMenu />
                  <AccountMenu />
                </Media>

                {/* Logged in menu expander on mobile */}
                <Media className="menu right" at="mobile">
                  <NotificationMenu />
                  {!userOptions?.isDamaged && mobileMenuButton}
                </Media>
              </>}

              {/* Logged out login button on all platforms */}
              {!data.isLoggedIn && <div className="menu right">
                <Menu.Item><Button color={config.site.navbarColor} inverted basic onClick={() => redirLogin()} >Log in / Register</Button></Menu.Item>
              </div>}

            </Container>
          </Menu>
          <Container style={{ margin: 'auto', padding: '60px 10px' }}>
            {networkIssues && <div style={{"position": "absolute", "right": "70px"}}>
              <Message color="yellow">
                <strong>Retrying...</strong>
                <p>A network error occured, please wait</p>
              </Message>
            </div>}

            <Breadcrumb icon='right angle' sections={defaultCrumbs.concat(breadcrumbs)} />

            {userCanView && children}
          </Container>
    </div>
  );
}

export default Layout
